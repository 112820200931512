const getHeaderTitle = (fullpath) => {
  if (fullpath.search('dashboard')>0) {
    return 'General Analytics'
  }

  if(fullpath.search('/purchase-order-farmer')>0){
    if(fullpath.search('/create')>0){
      return 'Create PO Farmer'
    }
    if(fullpath.search('/detail')>0){
      return 'PO Farmer Detail'
    }
    return 'PO Farmer'
  }
  
  if (fullpath.search('/export-hub')>0) {
    if(fullpath.search('export-hub/create')>0) {
      return 'Add Export Hub'
    }
    if(fullpath.search('export-hub/edit')>0) {
      return 'Edit Export Hub'
    }
    if(fullpath.search('export-hub/detail')>0) {
      return 'Facility Details - Export Hub'
    }
    return 'Export Hub'
  } if (fullpath.search('/hulling-facility')>0) {
    if(fullpath.search('hulling-facility/create')>0) {
      return 'Add Hulling Facility'
    }
    if(fullpath.search('hulling-facility/edit')>0) {
      return 'Edit Hulling Facility'
    }
    if(fullpath.search('hulling-facility/detail')>0) {
      return 'Facility Details - Hulling Facility'
    }
    return 'Hulling Facility'
  }
  if (fullpath.search('/pulping-facility')>0) {
    if(fullpath.search('pulping-facility/create')>0) {
      return 'Add Pulping Facility'
    }
    if(fullpath.search('pulping-facility/edit')>0) {
      return 'Edit Pulping Facility'
    }
    if(fullpath.search('pulping-facility/detail')>0) {
      return 'Facility Details - Pulping Facility'
    }
    return 'Pulping Facility'
  }
  if(fullpath.search('/pulping-activity')>0){
    if(fullpath.search('pulping-activity/detail')>0) {
      return 'Detail Pulping Activity'
    } else {
      return 'Pulping Activity'
    }
  } 
  if(fullpath.search('/hulling-activity')>0){
    if(fullpath.search('hulling-activity/detail')>0) {
      return 'Detail Hulling Activity'
    } else {
      return 'Hulling Activity'
    }
  }
  if (fullpath.search('/plantation')>0) {
    if(fullpath.search('plantation/create')>0) {
      return 'Add Plantation'
    }
    if(fullpath.search('plantation/edit')>0) {
      return 'Edit Plantation'
    }
    if(fullpath.search('plantation/detail')>0) {
      return 'Facility Details - Plantation'
    }
    return 'Plantation'
  } 
  if (fullpath.search('/partner')>0) {
    if(fullpath.search('partner/create')>0) {
      return 'Add Farmer'
    }
    if(fullpath.search('partner/edit')>0) {
      return 'Edit Farmer'
    }
    if(fullpath.search('partner/detail')>0) {
      return 'Farmer Details'
    }
    return 'Farmer List'
  }

  if(fullpath.search('/supplier')>0){
    return 'Supplier'
  }

  if (fullpath.search('/role')>0) {
    if(fullpath.search('role/create')>0) {
      return 'Add Role'
    }
    if(fullpath.search('role/edit')>0) {
      return 'Edit Role'
    }
    if(fullpath.search('role/detail')>0) {
      return 'Role Details'
    }
    return 'Role Management'
  }
  if (fullpath.search('/user')>0) {
    if(fullpath.search('user/create')>0) {
      return 'Add User'
    }
    if(fullpath.search('user/edit')>0) {
      return 'Edit User'
    }
    if(fullpath.search('user/detail')>0) {
      return 'User Details'
    }
    return 'User List'
  } else {
    return ''
  }
}

export default getHeaderTitle
