/* eslint-disable no-undef */
import 'dayjs/locale/id'
import * as dayjs from 'dayjs'
dayjs.locale('id')

// Date & Time
export const datetimeString = (date) => {
  if (date) return dayjs(date).format('DD/MM/YYYY, HH:mm')
  return null
}

export const dateString = (date) => {
  if (date) return dayjs(date).format('YYYY-MM-DD')
  return null
}

export const dateNowParse = (date) => {
  if (date) {
    return dayjs(date).format('DD/MM/YYYY')
  } else {
    return dayjs().format('DD/MM/YYYY')
  }
}

export const currency = (value) => {
  if (value) return `Rp ${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`
}

export const currencyDigit = (value) => {
  if (value) return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`
}
