<template>
  <table-content-plantation
    :images="images"
    :list-plantation="listPlantation"
    :set-toggle-modal-add-edit-discard="setToggleModalAddEditDiscard"
    :get-toggle-modal-add-edit-discard="getToggleModalAddEditDiscard"
  />
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import TableContentPlantation from '@/components/globals/table/facility/plantation/index'

export default {
  components: {
    TableContentPlantation
  },
  props: {
    images: {
      required: true,
      type: String
    },
    listPlantation: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      getToggleModalAddEditDiscard: 'plantation/get_toggle_modal_add_edit_discard'
    })
  },
  methods: {
    ...mapMutations({
      setToggleModalAddEditDiscard: 'plantation/SET_TOGGLE_MODAL_ADD_EDIT_DISCARD'
    })
  }
}
</script>
