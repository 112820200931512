<template>
  <div
    class="uk-form-stacked"
  >
    <div
      class="uk-child-width-1-1@s uk-grid-small"
      uk-grid
    >
      <div class="uk-width-1-1@s uk-width-1-3@l">
        <label class="uk-form-label">VCP Code or Facility Name</label>
        <div class="uk-form-controls">
          <input
            v-model="search"
            name="search"
            class="uk-input"
            type="text"
            placeholder="Search VCP Code or Facility Name"
            autocomplete="off"
          >
        </div>
      </div>
      <div class="uk-width-1-1@s uk-width-1-5@l">
        <div class="uk-form-controls">
          <label class="uk-form-label">Export Hub</label>
          <multiselect 
            v-model="export_hub" 
            label="name" 
            name="region" 
            track-by="id" 
            placeholder="All" 
            open-direction="bottom" 
            :options="listExportHub"
            :searchable="true"
            :max-height="150"
            :show-no-results="true"
            :show-labels="false"
          >
            <span slot="noResult">Oops! Data tidak ditemukan.</span>
          </multiselect>
        </div>
      </div>
      <div class="uk-width-1-1@s uk-width-1-5@l">
        <div class="uk-form-controls uk-position-relative withlabel">
          <label class="uk-form-label">Status</label>
          <select
            v-model="status"
            name="status"
            class="uk-width-expand custom-select"
            placeholder="Status"
          >
            <option
              v-for="(item, key) in statusFilter"
              :key="key"
              :value="item.value"
            >
              {{ item.name }}
            </option>
          </select>
          <img
            :src="`${images}/icon/ic_chevron_down.svg`"
            alt="ic_chevron"
          >
        </div>
      </div>
      <div class="uk-width-1-1@s uk-width-1-4@l uk-flex uk-flex-bottom">
        <button
          class="uk-button uk-button-primary uk-margin-small-right uk-padding-small"
          @click.prevent="handleSearch"
        >
          <img
            :src="`${images}/icon/ic_search_white.svg`"
            class="uk-margin-small-right"
            alt=""
            loading="lazy"
          >
          Search
        </button>
        <button
          class="uk-button uk-button-default uk-padding-remove"
          @click.prevent="handleReset"
        >
          Reset Filter
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { STATUS_FILTER } from '@/utils/constant'
export default {
  props: {
    images: {
      required: true,
      type: String
    },
    listExportHub: {
      required: true,
      type: Array
    },
    filterPlantation: {
      required: true,
      type: Function
    },
    resetFilterPlantation: {
      required: true,
      type: Function
    },
    metaFilter: {
      required: false,
      default: null,
      type: Object
    }
  },
  data() {
    return {
      search: '',
      export_hub: '',
      status: null,
      statusFilter: STATUS_FILTER
    }
  },
  computed: {
    ...mapGetters({
      getToggleModalAddEditDiscard: 'plantation/get_toggle_modal_add_edit_discard'
    })
  },
  methods: {
    ...mapMutations({
      setMetaFilter: "plantation/SET_META_FILTER"
    }),
    handleSearch() {
      this.setMetaFilter({
        ...this.metaFilter,
        search: this.search || null,
        export_hub_id: this.export_hub?.id,
        status: this.status
      })
      this.filterPlantation()
    },
    handleReset() {
      this.search = null
      this.export_hub = null,
      this.status = null

      this.setMetaFilter({
        ...this.metaFilter,
        search: null,
        export_hub_id: null,
        status: null
      })
      this.resetFilterPlantation()
    }
  }
}
</script>
