<template>
  <div
    id="modal-auth-info"
    :class="`${getToggleModalAddEditDiscard.targetModals ? 'uk-flex-top uk-modal uk-flex uk-open' : 'uk-modal'}`"
    uk-modal
    bg-close="false"
  >
    <div :class="`uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-text-center uk-modal-auth-info`">
      <div v-if="getToggleModalAddEditDiscard.type == 'save'">
        <h2 class="uk-modal-title save">
          {{ getToggleModalAddEditDiscard.title }} 
        </h2>
      </div>
      <div v-if="getToggleModalAddEditDiscard.type == 'delete'">
        <h2 class="uk-modal-title delete">
          {{ getToggleModalAddEditDiscard.title }} 
        </h2>
      </div>
      <div v-if="getToggleModalAddEditDiscard.type == 'discard'">
        <h2 class="uk-modal-title discard">
          {{ getToggleModalAddEditDiscard.title }} 
        </h2>
      </div>
      <p>
        {{ getToggleModalAddEditDiscard.description }}
      </p>
      <div class="uk-text-center uk-flex uk-flex-center">
        <button
          v-if="!getToggleModalAddEditDiscard.loadingPopUp"
          :class="`uk-button soft-green uk-modal-close uk-flex-center uk-width-1-1`"
          @click.prevent="handleCancel"
        >
          {{ getToggleModalAddEditDiscard.customTitleBtnCancel }}
        </button>
        <button
          v-else
          :class="`uk-button soft-green uk-modal-close uk-flex-center uk-width-1-1`"
          disabled
        >
          {{ getToggleModalAddEditDiscard.customTitleBtnCancel }}
        </button>
        <button
          v-if="!getToggleModalAddEditDiscard.loadingPopUp"
          :class="`uk-button uk-button-primary uk-flex-center uk-width-1-1`"
          type="button"
          @click="handleButton"
        >
          {{ getToggleModalAddEditDiscard.customTitleBtnOk }}
        </button>
        <button
          v-else
          :class="`uk-button uk-button-primary uk-flex-center uk-width-1-1`"
          type="button"
          disabled
        >
          <div uk-spinner />
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    setToggleModalAddEditDiscard: {
      required: false,
      default: null,
      type: Function
    },
    getToggleModalAddEditDiscard: {
      required: true,
      type: Object
    },
    handleModalButton: {
      required: true,
      type: Function
    }
  },
  methods: {
    async handleButton () {
      if(this.getToggleModalAddEditDiscard.type === 'discard') {
        this.$router.push({ name: this.getToggleModalAddEditDiscard.route })
        this.setToggleModalAddEditDiscard({
          targetModals: false,  
          title: '',
          description: '',
          customTitleBtnOk: '',
          customClass: '',
          route: '',
          loadingTable: false,
          loadingPopUp: false
        })
      } else {
        this.handleModalButton()
      }
    },
    handleCancel() {
      this.setToggleModalAddEditDiscard({
        targetModals: false,  
        title: '',
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        route: '',
        loadingTable: false,
        loadingPopUp: false
      })
    }
  }
}
</script>

<style scoped>
  .uk-modal-body {
    width: 400px;
    padding: 40px;
    box-shadow: 0px 8px 40px rgba(12, 50, 96, 0.05);
    border-radius: 24px;
  }
  .uk-modal-title {
    margin-bottom: 0px;
    font-family: 'interBold';
    font-weight: 700;
    font-size: 20px;
    text-align: center;
  }
  .uk-modal-title.save{
    color: #000000;
  }
  .uk-modal-title.delete{
    color: #EB5757;
  }
  .uk-modal-title.discard{
    color: #EB5757;
  }
  .uk-modal-close {
    margin-right: 16px;
  }
  .uk-modal-body p {
    margin: 16px 0px 24px 0px;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
  }
</style>
