<template>
  <div
    v-if="table === 'true'"
    class="label"
    style="width: 75px; height: 31px;"
  >
    {{ label || 'Active' }}
  </div>
  <div
    v-else
    class="label"
    style="width: 75px; height: 40px;"
  >
    {{ label || 'Active' }}
  </div>
</template>

<script>
export default {
  props: {
    label: {
      required: false,
      default: '',
      type: String
    },
    table: {
      required: false,
      default: "false",
      type: String
    }
  }
}
</script>

<style scoped>
.label {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  color: #14B614;
  background: rgba(20, 182, 20, 0.16);
  border: 1px solid rgba(20, 182, 20, 0.16);
  border-radius: 8px;
}
</style>
