<template>
  <div class="uk-margin-top">
    <div class="uk-overflow-auto border-table">
      <table
        class="uk-table uk-table-divider uk-table-middle"
        aria-describedby="plantation-table"
      >
        <header-plantation />
        <template v-if="getToggleModalAddEditDiscard.loadingTable">
          <loading-table :colspan="8" />
        </template>
        <template v-else-if="listPlantation.data && listPlantation.data.length > 0">
          <tbody>
            <tr
              v-for="(content, index) in listPlantation.data"
              :key="index"
            >
              <td>{{ content.vcf_code || '-' }}</td>
              <td>{{ content.facility_name || '-' }}</td>
              <td>{{ content.partner || '-' }}</td>
              <td>{{ content.export_hub?.name || '-' }}</td>
              <td>{{ content.size !=0 ? formatCurrency(content.size) : '-' || '-' }}</td>
              <td>{{ content.tree !=0 ? formatCurrency(content.tree) : '-' || '-' }}</td>
              <td>
                <div v-if="content.status === 1">
                  <label-status
                    status="active"
                    label="Active"
                    table="true"
                  />
                </div>
                <div v-else-if="content.status === 0">
                  <label-status
                    status="inactive"
                    label="Inactive"
                    table="true"
                  />
                </div>
              </td>
              <td class="action">
                <a
                  uk-tooltip="Delete"
                  :class="isCanAccessUser('delete', 'PLANTATION') ? '' : 'disabled'"
                >
                  <img
                    :src="`${images}/icon/${isCanAccessUser('delete', 'PLANTATION') ? 'ic_delete_table.svg' : 'ic_delete_disable.svg'}`"
                    alt=""
                    loading="lazy"
                    @click="showDeleteConfirmModal(content.id)"
                  >
                </a>
                <a
                  uk-tooltip="Edit"
                  :class="isCanAccessUser('edit', 'PLANTATION') ? '' : 'disabled'"
                >
                  <img
                    :src="`${images}/icon/${isCanAccessUser('edit', 'PLANTATION') ? 'ic_edit_table.svg' : 'ic_edit_disable.svg'}`"
                    alt=""
                    loading="lazy"
                    @click.prevent="$router.push({ name: 'PlantationEdit', params:{ id: content.id }})"
                  >
                </a>
                <a
                  uk-tooltip="View"
                  :class="isCanAccessUser('view', 'PLANTATION') ? '' : 'disabled'"
                >
                  <img
                    :src="`${images}/icon/${isCanAccessUser('view', 'PLANTATION') ? 'ic_eye_table.svg' : 'ic_eye_disable.svg'}`"
                    alt=""
                    loading="lazy"
                    @click.prevent="$router.push({ name: 'PlantationDetail', params:{ id: content.id }})"
                  >
                </a>
              </td>
            </tr>
          </tbody>
        </template>
        <template v-else>
          <empty-table
            :colspan="8"
          />
        </template>
      </table>
    </div>
  </div>
</template>

<script>
import HeaderPlantation from './HeaderPlantation'
import EmptyTable from "@/components/globals/table/EmptyTable"
import LoadingTable from "@/components/globals/table/LoadingTable"
import LabelStatus from '@/components/globals/LabelStatus'
import { currencyDigit } from '@/utils/formater'
import { isCanAccess } from '@/utils/auth'

export default {
  components: {
    HeaderPlantation,
    EmptyTable,
    LoadingTable,
    LabelStatus
  },
  props: {
    images: {
      required: true,
      type: String
    },
    listPlantation: {
      required: true,
      type: Object
    },
    setToggleModalAddEditDiscard: {
      required: false,
      default: null,
      type: Function
    },
    getToggleModalAddEditDiscard: {
      required: true,
      type: Object
    }
  },
  methods: {
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    formatCurrency(param) {
      return currencyDigit(param)
    },
    showDeleteConfirmModal(id) {
      this.setToggleModalAddEditDiscard({
        ...this.getToggleModalAddEditDiscard,
        targetModals: open,
        title: 'Delete Confirmation',
        description: 'Are you sure want to delete this data?',
        customTitleBtnOk: 'Delete',
        customTitleBtnCancel: 'Cancel',
        type: 'delete',
        loadingTable: false,
        loadingPopUp: false,
        id
      })
    }
  }
}
</script>

<style scoped>
.uk-margin-top {
  margin-top: 24px !important;
}
.disabled {
  cursor: no-drop;
}
</style>
