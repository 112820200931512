<template>
  <tbody>
    <tr>
      <td
        :colspan="colspan"
        class="uk-text-center"
      >
        {{ message || 'Data not found' }}
      </td>
    </tr>
  </tbody>
</template>

<script>
export default {
  props: {
    colspan: {
      required: true,
      type: Number
    },
    message: {
      default: '',
      required: false,
      type: String
    }
  }
}
</script>

<style scoped>
.uk-table td {
  font-family: 'interRegular';
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  white-space: nowrap;
}
</style>
