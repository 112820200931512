<template>
  <div>
    <active
      v-if="status == 'active'"
      :label="label"
      :table="table"
    />
    <inactive
      v-if="status == 'inactive'"
      :label="label"
      :table="table"
    />
    <unverified
      v-if="status == 'unverified'"
      :label="label"
      :table="table"
    />
  </div>
</template>

<script>
import Active from '@/components/globals/label/active'
import Inactive from '@/components/globals/label/inactive'
import Unverified from '@/components/globals/label/unverified'

export default {
  components: {
    Active,
    Inactive,
    Unverified
  },
  props: {
    status: { 
      required: true, 
      type: String 
    },
    label: { 
      required: true, 
      type: String 
    },
    table: { 
      required: true, 
      type: String 
    }
  }
}
</script>
