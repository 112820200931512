<template>
  <div
    class="uk-child-width-expand@s uk-grid-small uk-margin-top"
    uk-grid
  >
    <div class="uk-width-1-1@s uk-width-1-2@m uk-width-1-2@l uk-width-1-2@xl">
      <div class="uk-flex uk-flex-between uk-flex-between@s uk-flex-between@m uk-flex-left@l uk-flex-left@xl uk-flex-middle">
        <span class="row-per-page">Menampilkan</span>
        <div class="limit-page">
          <select
            id="form-horizontal-select"
            v-model="limitPage"
            class="uk-select"
            @change="changeLimit"
          >
            <option
              v-for="pages in limitList"
              :key="pages"
              :value="pages"
            >
              {{ pages }}
            </option>
          </select>
        </div>
        <span class="info">dari {{ totalData }} data</span>
      </div>
    </div>
    <div class="uk-width-1-1@s uk-width-1-2@m uk-width-1-2@l uk-width-1-2@xl">
      <div class="uk-flex uk-flex-center uk-flex-center@s uk-flex-between@m uk-flex-right@l uk-flex-right@xl uk-flex-middle">
        <button
          class="uk-button uk-button-text"
          :disabled="totalData === 0 || page === 1"
          @click="firstPage"
        >
          <span uk-icon="icon: chevron-double-left" />
        </button>
        <button
          class="uk-button uk-button-text"
          :disabled="totalData === 0 || page === 1"
          @click="toPage(-1)"
        >
          <span uk-icon="icon: chevron-left" />
        </button>
        <span
          v-show="currentLimitBox > 0"
          class="margin-left"
        >
          <b>...</b>
        </span>
        <span
          v-for="(count, i) in listBox[currentLimitBox]"
          :key="i"
        >
          <span
            :class="count === page ? 'pagination-active' : 'pagination'"
            @click="changePage(count)"
          >
            <b>{{ count }}</b>
          </span>
        </span>
        <span
          v-show="currentLimitBox + 1 < listBox.length"
          class="margin-left"
        >
          <b>...</b>
        </span>
        <button
          class="uk-button uk-button-text"
          :disabled="totalData === 0 || (page === Math.ceil(totalData / limitPage))"
          @click="toPage(1)"
        >
          <span uk-icon="icon: chevron-right" />
        </button>
        <button
          class="uk-button uk-button-text"
          :disabled="totalData === 0 || (page === Math.ceil(totalData / limitPage))"
          @click="lastPage"
        >
          <span uk-icon="icon: chevron-double-right" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    totalData: {
      required: true,
      type: Number,
      default: 0
    },
    changeLimit: {
      required: true,
      type: Function
    },
    changePage: {
      required: true,
      type: Function
    },
    currentPage: {
      required: false,
      default: 1,
      type: Number
    },
    // eslint-disable-next-line vue/require-prop-types
    currentLimit: {
      required: false,
      default: 10
    }
  },
  data() {
    return {
      pageCount: null,
      limitPage: 10,
      limitList: [10, 20, 50, 100],
      page: 1,
      limitBox: 5,
      listBox: [],
      currentLimitBox: 0
    }
  },
  computed: {
    firstInPage() {
      return this.page * this.limitPage - (this.limitPage-1)
    },
    lastInPage() {
      return this.totalData > this.page * this.limitPage ? this.page * this.limitPage : this.totalData
    },
    pageCounts() {
      return Math.ceil(this.totalData / this.limitPage)
    }
  },
  watch: {
    pageCounts() {
      this.listBox = []
      let pageLen = this.pageCounts
      this.setPages(pageLen)
    },
    currentPage() {
      this.page = this.currentPage
    },
    currentLimit() {
      this.limitPage = parseInt(this.currentLimit)
    }
  },
  mounted() {
    this.pageCount = Math.ceil(this.totalData / this.limitPage)
    this.listBox = []
    let pageLen = this.pageCount
    this.page = this.currentPage ? this.currentPage : 1
    this.setPages(pageLen)
  },
  methods: {
    setPages (pageLen) {
      let limit = this.limitBox
      let box = []
      let i = 1
      while (i <= pageLen) {
        if (i <= limit) {
          box.push(i)
          if (i == limit) {
            limit = limit + this.limitBox
            box = []
          }
          if (pageLen%this.limitBox === box.length || pageLen%this.limitBox === 0) {
            this.listBox.push(box)
          }
        }
        i++
      }
    },
    toPage(val) {
      let findExist = 0
      this.page += val
      for (let i = 0; i < this.listBox.length; i++) {
        let findPage = this.listBox[i].indexOf(this.page)
        findExist = i
        if (findPage != -1) {
          break
        }
      }
      this.currentLimitBox = findExist
      this.changePage(this.page)
    },
    firstPage() {
      this.page = 1
      this.currentLimitBox = 0
      this.changePage(this.page)
    },
    lastPage() {
      this.page = Math.ceil(this.totalData / this.limitPage)
      this.currentLimitBox = this.listBox.length - 1
      this.changePage(this.page)
    }
  }
}
</script>

<style scoped>
#form-horizontal-select {
  height: 32px;
  background: #F4F4F4;
  border-radius: 8px;
  padding-right: 10px;
  -webkit-appearance: auto;
  background-image: url('/images/icon/select.svg');
}
span.pagination {
  display: inline;
  width: 100px;
  height: 100px;
  padding: 5px 10px;
  font-weight: 400;
  color: #828282;
  border-radius: 4px;
  box-sizing: border-box;
  border: none;
  margin-left: 10px;
  cursor: pointer;
}
span.pagination:hover {
  color: #000000;
}
span.pagination-active {
  display: inline;
  width: 100px;
  height: 100px;
  padding: 5px 10px;
  border-radius: 4px;
  box-sizing: border-box;
  margin-left: 10px;
  cursor: pointer;
  background-color: #f4f4f4;
  color: #000000;
}
span.margin-left {
  margin-left: 10px;
}
.pagination {
  display: flex;
  border-top: 1px solid #e5e5e5;
  line-height: 54px;
  height: 54px;
  padding: 0 20px;
  font-size: 13px;
}
.row-per-page {
  flex: 0;
  font-weight: 400;
  color: #000000;
}
.limit-page {
  margin-left: 10px;
}
.uk-select {
  border: none;
  height: 100%;
  line-height: normal;
  cursor: pointer;
}
.info {
  margin-left: 10px;
  font-weight: 400;
  color: #000000;
}
.action {
  margin-left: 20px;
  margin-top: -2px;
  flex: 1;
  text-align: right;
}
.uk-button {
  margin-left: 10px;
}
@media screen and (max-width: 488px) {
  .pagination {
    padding: 0 5px;
  }
  .info {
    margin-left: 10px;
  }
}
</style>
